<template>
    <div class="upload">
        <div v-if="!isSuccess">
            <div class="content">
                <p>
                    上传文件<span>(点击上传或者文件直接拖入)</span>
                    <el-button
                        size="mini"
                        style="
                            background: #fff;
                            color: #2370eb;
                            border-color: #2370eb;
                            padding: 4px 15px;
                            margin-left: 8px;
                        "
                        @click="downLoadModel"
                    >
                        下载模板
                    </el-button>
                </p>
                <p>
                    <el-upload
                        class="upload-demo"
                        :action="action"
                        :on-success="onExcel"
                        :before-upload="beforeAvatarUploads"
                        drag
                        :limit="1"
                        ref="businessLicense"
                        accept=".doc,.docx,.xls,.xlsx,"
                        :file-list="businessLicense"
                        :before-remove="removeExcel"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                            将文件拖拽到此区域 <br /><el-button
                                size="mini"
                                style="
                                    background: #2370eb;
                                    color: #fff;
                                    border-color: #2370eb;
                                    margin-top: 10px;
                                "
                            >
                                点击上传
                            </el-button>
                        </div>
                    </el-upload>
                </p>
            </div>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onSubmit"
                    >上传</el-button
                >
            </div>
        </div>
        <div v-else>
            <div class="content" style="height: 200px">
                <p>
                    <i class="el-icon-circle-check"></i>
                </p>
                <p style="font-size: 16px">
                    订单导入已完成，共导入{{ upSuccessData.total || 0 }}条信息
                </p>
                <p style="font-size: 16px">
                    成功导入 {{ upSuccessData.successTotal || 0 }} 条；失败
                    {{ upSuccessData.failTotal || 0 }} 条
                </p>
                <p style="font-size: 16px">点击下载导入结果文档</p>
                <p>
                    <el-button
                        size="small"
                        style="
                            background: #fff;
                            color: #2370eb;
                            border-color: #2370eb;
                            font-size: 14px;
                            margin-top: 16px;
                        "
                        @click="downLoad"
                    >
                        下载导入结果
                    </el-button>
                </p>
            </div>
            <div class="but">
                <el-button
                    round
                    type="primary"
                    size="mini"
                    style="
                        background: none;
                        border-color: #d5d6df;
                        color: #666666;
                        margin-left: auto;
                    "
                    @click="onClose"
                    >取消</el-button
                >
                <el-button
                    round
                    type="primary"
                    style="
                        background: #2370eb;
                        border-color: #2370eb;
                        color: #fff;
                        margin-right: 30px;
                    "
                    size="mini"
                    @click="onClose"
                    >确定</el-button
                >
            </div>
        </div>
    </div>
</template>
<script>
import { invoiceImport } from '@/api/invoice/invoice.js';
export default {
    data() {
        return {
            action: this.$url.url() + '/file/local/upload',
            fileName: '',
            fileShowName: '',
            businessLicense: [],
            isSuccess: false,
            upSuccessData: {},
            productCategory: '',
        };
    },
    methods: {
        getData(productCategory) {
            this.businessLicense = [];
            this.month = '';
            this.isSuccess = false;
            this.upSuccessData = {};
            this.productCategory = productCategory;
        },
        // 上传
        onExcel(response, file) {
            this.fileShowName = file.name;
            this.fileName = '';
            if (response.code == 200) {
                this.fileName = response.data.newFileName;
                this.$message.success('操作成功');
            } else {
                this.$message.error('操作失败');
            }
        },
        beforeAvatarUploads(file) {
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 20MB!');
            }
            return isLt2M;
        },
        removeExcel() {
            this.fileName = '';
            this.fileShowName = '';
            this.businessLicense = [];
        },
        onSubmit() {
            if (this.fileName == '') {
                return this.$message.error('请上传文件');
            }
            let data = {
                param: {
                    fileName: this.fileName,
                    productCategory: this.productCategory,
                },
            };
            invoiceImport(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('上传成功');
                    this.isSuccess = true;
                    this.upSuccessData = res.data;
                }
                // this.onClose();
            });
        },
        onClose() {
            this.$emit('close');
            this.businessLicense = [];
            this.month = '';
            this.isSuccess = false;
            this.upSuccessData = {};
        },
        downLoad() {
            window.open(this.$url.xlsxUrl() + this.upSuccessData.resultFileName);
        },
        downLoadModel() {
            window.open(this.$url.xlsxUrl() + 'model/invoice.xlsx');
        },
    },
};
</script>
<style lang="scss" scoped>
.upload {
    height: 100%;

    > div {
        display: flex;
        flex-direction: column;
    }
    .content {
        height: 300px;
        padding: 32px 0;
        p {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            margin-bottom: 8px;
            span {
                color: #999;
            }
            .el-icon-circle-check {
                color: #2370eb;
                font-size: 40px;
            }
        }
    }
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
</style>
