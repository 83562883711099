<template>
    <div class="add">
        <el-form
            label-position="right"
            label-width="120px"
            size="small"
            style="flex: 1; margin-top: 20px"
        >
            <el-form-item
                label="申请编号"
                required
                v-for="(item, index) in invoiceRecordContractDOList"
            >
                <el-select
                    v-model="item.contractInvoiceId"
                    style="width: 290px"
                    clearable
                    filterable
                    size="small"
                    placeholder="请选择或搜索"
                    @change="
                        (val) => {
                            chooseContractInvoiceId(val, index);
                        }
                    "
                    :remote-method="getInvoiceData"
                >
                    <el-option
                        v-for="itm in invoiceData"
                        :key="itm.id"
                        :label="itm.invoiceNo"
                        :value="itm.id"
                    ></el-option>
                </el-select>
                <el-button
                    v-if="index == 0"
                    @click="addContractInvoice"
                    type="primary"
                    class="el-icon-plus"
                    circle
                    size="mini"
                ></el-button>
                <el-button
                    v-if="index > 0"
                    @click.prevent="removeContractInvoice(index)"
                    class="el-icon-minus"
                    type="primary"
                    circle
                    size="mini"
                ></el-button>
            </el-form-item>
            <el-form-item label="开票日期" required>
                <el-date-picker
                    v-model="form.invoiceTime"
                    size="small"
                    style="width: 290px"
                    type="date"
                    placeholder="请选择日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item label="发票号码" required>
                <el-input
                    style="width: 290px"
                    placeholder="请输入发票号码"
                    v-model="form.invoiceNo"
                ></el-input>
            </el-form-item>
            <el-form-item label="员工" required>
                <el-cascader
                    :options="userData"
                    :props="{
                        value: 'id',
                        label: 'nickName',
                        children: 'children',
                    }"
                    :show-all-levels="false"
                    style="width: 290px"
                    size="small"
                    v-model="collectionAdminId"
                ></el-cascader
            ></el-form-item>
            <el-form-item label="开票类别" required>
                <el-select
                    v-model="form.invoiceType"
                    size="small"
                    style="width: 290px"
                    placeholder="请选择开票类别"
                >
                    <el-option label="增值税普通发票" value="增值税普通发票">
                    </el-option>
                    <el-option label="增值税专用发票" value="增值税专用发票">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开户名称" required>
                <el-input
                    style="width: 290px"
                    placeholder="请输入开户名称"
                    v-model="form.invoiceName"
                ></el-input>
            </el-form-item>
            <el-form-item label="开票公司" required>
                <el-select
                    v-model="form.company"
                    style="width: 290px"
                    clearable
                    size="small"
                    placeholder="请选择开票公司"
                >
                    <el-option
                        v-for="item in $tableDataHandle.selectTypeB()"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        v-show="item.value != 9"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="不含税金额" required>
                <el-input
                    style="width: 290px"
                    placeholder="请输入不含税金额"
                    v-model="form.taxExcludedAmount"
                    @change="getAmount()"
                ></el-input
            ></el-form-item>
            <el-form-item label="税额" required>
                <el-input
                    style="width: 290px"
                    placeholder="请输入税额"
                    v-model="form.taxAmount"
                    @change="getAmount()"
                ></el-input
            ></el-form-item>
            <el-form-item label="开票金额" required>
                {{ form.invoiceAmount }}</el-form-item
            >
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 170px;
                "
                @click="onClose"
                >取 消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >保 存</el-button
            >
            <el-button
                round
                type="primary"
                size="mini"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                @click="onDelete"
                v-if="btnP.nullify"
                >作废</el-button
            >
        </div>
    </div>
</template>
<script>
import { userList, departmentList } from '@/api/admin/framework/framework.js';
import { invoiceUpDate, invoiceGet } from '@/api/invoice/invoice.js';
import { invoiceList } from '@/api/contr/invoice.js';
export default {
    data() {
        return {
            form: {
                invoiceAmount: 0,
                contractInvoiceId: '',
            },
            userData: [],
            collectionAdminId: [],
            btnP: {},
            invoiceData: [],
            invoiceRecordContractDOList: [{}],
            data: {},
        };
    },
    methods: {
        remoteMethod(query) {
            this.getInvoiceData(query);
        },
        getInvoiceData(name = '') {
            let data = {
                param: {
                    statusList: [2, 5],
                },
                pageNum: 0,
                pageSize: 0,
            };
            if (name) {
                data.param.name = name;
            }
            data.param.contractStatusList = [7, 8, 10, 11, 12];
            invoiceList(data).then((res) => {
                this.invoiceData = res.data.list;
                this.invoiceData.push({
                    id: this.data.contractInvoiceId,
                    invoiceNo: this.data.contractInvoiceNo,
                });
                this.form.invoiceRecordContractBOList &&
                this.form.invoiceRecordContractBOList.length > 0
                    ? (this.invoiceRecordContractDOList =
                          this.form.invoiceRecordContractBOList)
                    : (this.invoiceRecordContractDOList = [{}]);
            });
        },
        async getData(data, btn) {
            console.log(data);
            this.data = data;
            await this.getInvoiceData();
            this.btnP = btn;
            await this.getUserData();
            let obj = {
                param: {
                    id: data.id,
                },
            };
            await invoiceGet(obj).then((res) => {
                this.form = data;

                this.collectionAdminId = [];
            });
            this.getAmount();
        },
        chooseContractInvoiceId(val, index) {
            this.invoiceRecordContractDOList.forEach((item, idx) => {
                if (index != idx && val == item.contractInvoiceId) {
                    this.invoiceRecordContractDOList[index].contractInvoiceId =
                        '';
                    this.invoiceRecordContractDOList = JSON.parse(
                        JSON.stringify(this.invoiceRecordContractDOList)
                    );
                    return this.$message.error('合同发票申请编号重复');
                }
            });
        },
        addContractInvoice() {
            this.invoiceRecordContractDOList.push({});
        },
        removeContractInvoice(index) {
            this.invoiceRecordContractDOList.splice(index, 1);
        },
        getUserData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                res.data.forEach((i) => {
                    i.nickName = i.departmentName;
                    let obj = {
                        param: {
                            id: i.id,
                        },
                        pageNum: 0,
                        pageSize: 0,
                    };
                    userList(obj).then((r) => {
                        i.children = r.data.list;
                    });
                });
                setTimeout(() => {
                    this.userData = res.data;
                    this.collectionAdminId = [
                        this.form.collectionDepartmentId,
                        this.form.collectionAdminId,
                    ];
                }, 800);
            });
        },
        getAmount() {
            if (this.form.taxExcludedAmount && this.form.taxAmount) {
                this.form.invoiceAmount =
                    Number(this.form.taxExcludedAmount) +
                    Number(this.form.taxAmount);
            }
        },
        onSubmit() {
            // if (!this.form.contractInvoiceId) {
            //     return this.$message.error('请选择合同发票申请编号');
            // }
            for (let i in this.invoiceRecordContractDOList) {
                if (!this.invoiceRecordContractDOList[i].contractInvoiceId) {
                    return this.$message.error('请选择合同发票申请编号');
                }
            }
            if (!this.form.invoiceTime) {
                return this.$message.error('请选择开票日期');
            }
            if (!this.form.invoiceNo) {
                return this.$message.error('请输入发票号码');
            }
            if (!this.collectionAdminId) {
                return this.$message.error('请选择员工');
            }
            if (!this.form.invoiceType) {
                return this.$message.error('请选择开票类别');
            }
            if (!this.form.invoiceName) {
                return this.$message.error('请输入开户名称');
            }
            if (!this.form.company) {
                return this.$message.error('请选择开票公司');
            }
            if (!this.form.taxExcludedAmount) {
                return this.$message.error('请输入不含税金额');
            }
            if (!this.form.taxAmount) {
                return this.$message.error('请输入税额');
            }

            this.form.collectionDepartmentId = this.collectionAdminId[0];
            this.form.collectionAdminId =
                this.collectionAdminId[this.collectionAdminId.length - 1];
            let data = {
                param: {
                    contractInvoiceId: this.form.contractInvoiceId,
                    invoiceTime: this.form.invoiceTime,
                    invoiceNo: this.form.invoiceNo,
                    collectionAdminId: this.form.collectionAdminId,
                    invoiceType: this.form.invoiceType,
                    invoiceName: this.form.invoiceName,
                    company: this.form.company,
                    taxExcludedAmount: this.form.taxExcludedAmount,
                    invoiceAmount: this.form.invoiceAmount,
                    taxAmount: this.form.taxAmount,
                    collectionDepartmentId: this.form.collectionDepartmentId,
                    id: this.form.id,
                    invoiceRecordContractDOList:
                        this.invoiceRecordContractDOList,
                },
            };
            invoiceUpDate(data).then((res) => {
                if (res.code == 200) {
                    this.form = {};
                    this.$message.success('修改成功');
                    this.onClose();
                }
            });
        },
        onDelete() {
            let data = {
                param: {
                    id: this.form.id,
                    status: 2,
                },
            };
            invoiceUpDate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.add {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.bottom-but {
    width: 480px;
    line-height: 48px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
        width: 90px;
        z-index: 1000;
    }
}
.el-button--mini.is-circle {
    padding: 2px;
    background: #fff;
    color: #2370eb;
    font-weight: 600;
    width: 20px;
    height: 20px;
    border: 2px solid #2370eb;
    margin-left: 5px;
}
</style>
