<template>
    <div class="conlist">
        <div class="search_box">
            <div class="search_left">
                <p>
                    <span>时间</span>
                    <el-date-picker
                        v-model="searchVal.month"
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="全部"
                        size="small"
                        style="width: 170px"
                        @change="
                            (currentPage = 1), (pagesize = 20), getTableData()
                        "
                    >
                    </el-date-picker>
                </p>
                <p>
                    <span>开票公司</span>
                    <el-select
                        v-model="searchVal.company"
                        style="width: 170px"
                        clearable
                        size="small"
                        placeholder="全部"
                        @change="
                            (currentPage = 1), (pagesize = 20), getTableData()
                        "
                    >
                        <el-option
                            v-for="item in $tableDataHandle.selectTypeB()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-show="item.value != 9"
                        ></el-option>
                    </el-select>
                </p>
                <p>
                    <span>发票状态</span>
                    <el-radio-group
                        size="small"
                        v-model="searchVal.statusVal"
                        @change="
                            (currentPage = 1), (pagesize = 20), getTableData()
                        "
                    >
                        <el-radio-button label="1">正常</el-radio-button>
                        <el-radio-button label="2">已作废</el-radio-button>
                    </el-radio-group>
                </p>
                <p>
                    <Department
                        ref="department"
                        :departmentWidth="170"
                        :adminWidth="170"
                        :depSearch="btnP.depSearch"
                        :searchUser="btnP.userSearch"
                        @searchData="searchData"
                    ></Department>
                    <!-- <span v-show="btnP.depSearch">部门</span>
                    <el-select
                        v-show="btnP.depSearch"
                        v-model="searchVal.dempVal"
                        size="small"
                        style="width: 170px"
                        clearable
                        placeholder="全部"
                        @change="
                            (currentPage = 1),
                                (pagesize = 20),
                                changeDepartmentId()
                        "
                    >
                        <el-option
                            v-for="item in dempData"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                    <span v-show="btnP.userSearch">成员</span>
                    <el-select
                        v-show="btnP.userSearch"
                        v-model="searchVal.adminVal"
                        size="small"
                        style="width: 170px"
                        clearable
                        placeholder="全部"
                        @change="
                            (currentPage = 1),
                                (pagesize = 20),
                                (listType = ''),
                                getTableData()
                        "
                    >
                        <el-option
                            v-for="item in userData"
                            :key="item.id"
                            :label="item.nickName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select> -->
                </p>
                <p>
                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 220px"
                        placeholder="发票号码、开户名称、开票金额"
                        v-model="searchVal.fieldVal"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="
                            (currentPage = 1), (pagesize = 50), getTableData()
                        "
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                </p>
            </div>
            <div class="search_right">
                <div>
                    <p>
                        <img src="@/img/invoice1.png" alt="" />
                        开票金额合计
                    </p>
                    <p>{{ briefData.invoiceTotalAmount || '0' }}</p>
                </div>
                <div>
                    <p><img src="@/img/invoice2.png" alt="" />税额合计</p>

                    <p>{{ briefData.taxTotalAmount || '0' }}</p>
                </div>
            </div>
        </div>
        <div class="table-title">
            <p>发票记录</p>
            <span>
                <button @click="onImport()" v-show="btnP.Import" class="cancel">
                    导入记录
                </button>
                <button @click="onAdd()" class="cancel">新增记录</button>
            </span>
        </div>
        <el-table
            class="table-box"
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column prop="" width="10"> </el-table-column>
            <el-table-column label="开票日期" width="93" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{
                        scope.row.invoiceTime
                            ? scope.row.invoiceTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="发票号码"
                prop="companyName"
                show-overflow-tooltip
                width="139"
            >
                <template slot-scope="scope">
                    <el-button
                        v-if="btnP.details && searchVal.statusVal == 1"
                        type="text"
                        style="color: #2370eb"
                        @click="onDetails(scope.row)"
                        >{{ scope.row.invoiceNo }}</el-button
                    >
                    <span v-else> {{ scope.row.invoiceNo || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="员工"
                width="80"
                prop=""
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.collectionAdminName || '- -' }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="发票类型"
                width="150"
                prop=""
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.invoiceType }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="开户名称"
                width="239"
                prop=""
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.invoiceName }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="发票状态" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>
                        {{ scope.row.status == 1 ? '正常' : '已作废' }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="开票金额" width="80" show-overflow-tooltip>
                <template slot-scope="scope">
                    <span> {{ scope.row.invoiceAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="不含税金额"
                width="80"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.taxExcludedAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="税额"
                prop="createTime"
                show-overflow-tooltip
                width="80"
            >
                <template slot-scope="scope">
                    <span> {{ scope.row.taxAmount }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="开票公司"
                prop="adminName"
                width="209"
                show-overflow-tooltip
            >
                <template slot-scope="scope">
                    <span>
                        {{
                            $tableDataHandle.echoTypeB(scope.row.company)
                        }}</span
                    >
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="申请编号"
                prop="contractInvoiceNo"
                show-overflow-tooltip
                width="150"
            >
                <template slot-scope="scope">
                    <el-tooltip
                        class="item"
                        effect="dark"
                        content="Top Center 提示文字"
                        placement="top"
                    >
                        <div slot="content">
                            <p
                                style="line-height: 1.8"
                                v-for="item in scope.row
                                    .invoiceRecordContractBOList"
                                :key="item.contractInvoiceId"
                            >
                                {{ item.contractInvoiceNo }}
                            </p>
                        </div>
                        <span
                            style="cursor: pointer"
                            v-if="
                                scope.row.invoiceRecordContractBOList &&
                                scope.row.invoiceRecordContractBOList[0]
                            "
                            >{{
                                scope.row.invoiceRecordContractBOList[0]
                                    .contractInvoiceNo || '- -'
                            }}
                            <i class="circle_box">{{
                                scope.row.invoiceRecordContractBOList.length
                            }}</i>
                        </span>
                        <span v-else>- -</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <div class="page-box" v-if="isPage">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 导入记录 -->
        <el-dialog
            :visible.sync="dialogImport"
            width="460px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    导入记录
                </div>
            </template>
            <Import @close="handleClose" ref="import" />
        </el-dialog>
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleDrawer"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    新增记录
                </div>
            </template>
            <div>
                <AddInvoice ref="invoice" @close="handleDrawer" />
            </div>
        </el-drawer>
        <el-drawer
            :visible.sync="drawerEdit"
            :direction="direction"
            :before-close="handleDrawer"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    记录详情
                </div>
            </template>
            <div>
                <EditInvoice ref="invoiceEdit" @close="handleDrawer" />
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {
    invoiceList,
    invoiceBrief,
    invoiceAdd,
} from '@/api/invoice/invoice.js';
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import Import from './import.vue';
import AddInvoice from './add.vue';
import EditInvoice from './edit.vue';
import Department from '../../components/Department';
export default {
    components: {
        Import,
        AddInvoice,
        EditInvoice,
        Department,
    },
    data() {
        return {
            btnP: {},
            searchVal: {
                statusVal: '1',
                month: '',
            },
            dempData: [],
            userData: [],
            tableData: [],
            pagesize: 20,
            total: 0,
            currentPage: 1,
            isPage: false,
            dialogImport: false,
            drawerEdit: false,
            drawer: false,
            direction: 'rtl',
            briefData: {},
        };
    },
    mounted() {
        if (
            sessionStorage.getItem('pageData') &&
            JSON.parse(sessionStorage.getItem('pageData'))
        ) {
            this.currentPage = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageNum;
            this.pagesize = JSON.parse(
                sessionStorage.getItem('pageData')
            ).pageSize;
            sessionStorage.removeItem('pageData');
            this.isPage = true;
        } else {
            this.isPage = true;
        }

        this.searchVal.month =
            this.$searchTime.DateUtil.getStartDayOfMonth().slice(0, 7);
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            if (btn.userSearch && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            if (btn.depSearch) {
                this.getDempData();
            }
            this.getTableData();
        },
        getDempData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.dempData = res.data;
                this.$refs.department.getData(this.btnP, res.data);
            });
        },
        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.searchVal.dempVal = data.departmentId;
            this.searchVal.adminVal = data.adminId;
            this.getTableData();
        },

        getTableData() {
            let data = {
                param: {
                    status: this.searchVal.statusVal,
                },
                pageNum: this.currentPage,
                pageSize: this.pagesize,
            };
            if (!this.btnP.depSearch && this.btnP.userSearch) {
                data.param.collectionDepartmentId =
                    sessionStorage.getItem('departmentId');
                if (this.searchVal.adminVal) {
                    data.param.collectionAdminId = this.searchVal.adminVal;
                }
            }
            if (this.btnP.depSearch && !this.btnP.userSearch) {
                data.param.collectionDepartmentId = this.searchVal.dempVal;
            }
            if (this.btnP.depSearch && this.btnP.userSearch) {
                data.param.collectionDepartmentId = this.searchVal.dempVal;
                data.param.collectionAdminId = this.searchVal.adminVal;
            }
            if (!this.btnP.depSearch && !this.btnP.userSearch) {
                data.param.collectionAdminId =
                    sessionStorage.getItem('adminId');
            }

            if (this.searchVal.fieldVal && this.searchVal.fieldVal != '') {
                data.param.name = this.searchVal.fieldVal;
            }
            if (this.searchVal.month && this.searchVal.month != '') {
                data.param.month = this.searchVal.month;
            }
            if (this.searchVal.company && this.searchVal.company != '') {
                data.param.company = this.searchVal.company;
            }
            invoiceList(data).then((res) => {
                // console.log(res);
                this.total = res.data.total;
                this.tableData = res.data.list;
            });
            invoiceBrief(data).then((res) => {
                console.log(res);
                this.briefData = res.data;
            });
        },

        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getTableData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getTableData();
        },
        onImport() {
            this.dialogImport = true;
        },
        handleClose() {
            this.dialogImport = false;
            this.getTableData();
        },
        // 复制
        onCopy(item) {
            let url = item.expressNo;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
        searchEmpty() {
            this.searchVal = {
                statusVal: '',
            };
            this.getTableData();
        },
        onAdd() {
            this.drawer = true;
            setTimeout(() => {
                this.$refs.invoice.getUserData();
            });
        },
        onDetails(row) {
            this.drawerEdit = true;
            setTimeout(() => {
                this.$refs.invoiceEdit.getData(row, this.btnP);
            });
        },
        handleDrawer() {
            this.drawer = false;
            this.drawerEdit = false;
            this.getTableData();
        },
    },
};
</script>

<style lang="scss" scoped>
.conlist {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .search_box {
        font-size: 13px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 12px;
        .search_left {
            flex: 1;
            display: flex;
            flex-flow: wrap;
            background: #fff;
            margin-right: 12px;
            border-radius: 4px;
            padding-top: 12px;
            p {
                margin-bottom: 10px;
            }
            button {
                background: #2370eb;
                border-radius: 2px;
                margin-right: 16px;
                margin-top: 0;
            }
        }
        .search_right {
            background: #fff;
            border-radius: 4px;
            width: 424px;
            display: flex;
            div {
                flex: 1;
            }
            p:nth-child(1) {
                padding: 18px 18px;
            }
            p:nth-child(2) {
                padding-bottom: 20px;
                margin-left: 50px;
                font-size: 16px;
            }
            p {
                display: flex;
                img {
                    margin-right: 8px;
                }
            }
        }
        span {
            font-size: 12px;
            font-weight: 550;
            color: #333333;
            margin: 0 16px;
        }
        button {
            background: #2370eb;
            border-radius: 2px;
            margin-right: 16px;
        }
    }

    .table-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 16px;
        .add {
            border-color: #d9d9d9;
            color: #333333;
        }
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }

        button {
            font-size: 12px;
            padding: 0 7px 0 12px;
            height: 24px;
            background: #ffffff;
            border-radius: 2px;
            border: 1px solid #2370eb;
            cursor: pointer;
            color: #2370eb;
            margin-left: 16px;
            text-align: center;
        }

        button:hover {
            background: #2370eb;
            color: #ffffff;
            border-color: #2370eb;
        }
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.content {
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 16px;
    color: #333;
}
.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.isRed {
    color: red;
}
.circle_box {
    width: 20px;
    height: 20px;
    background-color: #2370eb;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
    font-size: 14px;
    display: inline-block;
    font-style: normal;
}
</style>
